'use client'

import { useEffect } from 'react'

import { useRouter } from '@/i18n/navigation'
import { sessionExpiredOrExpiringIn30Sec } from '@/logged-in/utils/commonUtils'
import { useLocale } from '@/shared/locale'
import { useClientSideConfs } from '@/shared/providers/appEnv'
import { routes, serviceBreakClientSearchParam } from '@/shared/routes'
import { signInHandler } from '@/shared/utils/signInHandler'

export const LoginPage = ({
  sessionExpires,
  isServiceBreakEnabled,
}: {
  sessionExpires: string | undefined
  isServiceBreakEnabled: boolean
}) => {
  const clientSideConfs = useClientSideConfs()
  const router = useRouter()
  const locale = useLocale()

  useEffect(
    () => {
      if (!sessionExpiredOrExpiringIn30Sec(sessionExpires)) {
        router.replace('/my/overview')
      } else {
        void redirect()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const redirect = async () => {
    if (isServiceBreakEnabled) {
      router.push({
        pathname: routes.SERVICE_BREAK,
        params: { client: serviceBreakClientSearchParam.LOG_IN },
      })
    } else {
      const params = new URLSearchParams(window.location.search)

      await signInHandler(
        clientSideConfs.ciam.providerId,
        locale,
        params.get('redirectUrl') ?? undefined,
      )
    }
  }

  return null
}
